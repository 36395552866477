// Generated by Framer (4175fb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cNgXObNqG", "Ir7dzAahl"];

const serializationHash = "framer-OIQGX"

const variantClassNames = {cNgXObNqG: "framer-v-gjumth", Ir7dzAahl: "framer-v-12f6dhu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "cNgXObNqG", "Variant 2": "Ir7dzAahl"}

const getProps = ({height, id, link, newTab, title, width, ...props}) => { return {...props, CwkTTHzdi: newTab ?? props.CwkTTHzdi, RZtqQWWoV: title ?? props.RZtqQWWoV ?? "ABOUT", TSMEVGiE1: link ?? props.TSMEVGiE1, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cNgXObNqG"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RZtqQWWoV, TSMEVGiE1, CwkTTHzdi, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cNgXObNqG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1sb76l9 = activeVariantCallback(async (...args) => {
setVariant("Ir7dzAahl")
})

const onMouseLeaveflkamf = activeVariantCallback(async (...args) => {
setVariant("cNgXObNqG")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-gjumth", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"cNgXObNqG"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1sb76l9} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Ir7dzAahl: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeaveflkamf}}, baseVariant, gestureVariant)}><Link href={TSMEVGiE1} openInNewTab={CwkTTHzdi}><motion.a className={"framer-13p0if3 framer-sj8kls"} layoutDependency={layoutDependency} layoutId={"xadkJsjC5"}><motion.div className={"framer-1nb8p49"} layoutDependency={layoutDependency} layoutId={"kjylqRupB"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U3dpdHplci1tZWRpdW0=", "--framer-font-family": "\"Switzer\", \"Switzer Placeholder\", sans-serif", "--framer-font-size": "46px", "--framer-font-weight": "500", "--framer-letter-spacing": "0px", "--framer-line-height": "47px", "--framer-text-alignment": "center", "--framer-text-transform": "uppercase"}}>ABOUT</motion.p></React.Fragment>} className={"framer-1q34xtm"} fonts={["FS;Switzer-medium"]} layoutDependency={layoutDependency} layoutId={"w6bc8RfRp"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", opacity: 1}} text={RZtqQWWoV} variants={{Ir7dzAahl: {opacity: 0.2}}} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OIQGX.framer-sj8kls, .framer-OIQGX .framer-sj8kls { display: block; }", ".framer-OIQGX.framer-gjumth { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-OIQGX .framer-13p0if3 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-OIQGX .framer-1nb8p49 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-OIQGX .framer-1q34xtm { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OIQGX.framer-gjumth, .framer-OIQGX .framer-13p0if3, .framer-OIQGX .framer-1nb8p49 { gap: 0px; } .framer-OIQGX.framer-gjumth > *, .framer-OIQGX .framer-13p0if3 > *, .framer-OIQGX .framer-1nb8p49 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OIQGX.framer-gjumth > :first-child, .framer-OIQGX .framer-13p0if3 > :first-child, .framer-OIQGX .framer-1nb8p49 > :first-child { margin-left: 0px; } .framer-OIQGX.framer-gjumth > :last-child, .framer-OIQGX .framer-13p0if3 > :last-child, .framer-OIQGX .framer-1nb8p49 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 156
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Ir7dzAahl":{"layout":["auto","auto"]}}}
 * @framerVariables {"RZtqQWWoV":"title","TSMEVGiE1":"link","CwkTTHzdi":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfArhDNW8N: React.ComponentType<Props> = withCSS(Component, css, "framer-OIQGX") as typeof Component;
export default FramerfArhDNW8N;

FramerfArhDNW8N.displayName = "Open nav link";

FramerfArhDNW8N.defaultProps = {height: 47, width: 156};

addPropertyControls(FramerfArhDNW8N, {variant: {options: ["cNgXObNqG", "Ir7dzAahl"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, RZtqQWWoV: {defaultValue: "ABOUT", displayTextArea: false, title: "Title", type: ControlType.String}, TSMEVGiE1: {title: "Link", type: ControlType.Link}, CwkTTHzdi: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerfArhDNW8N, [{explicitInter: true, fonts: [{family: "Switzer", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/OYB4CXKJQXKTNSLJMTDQOIVUL2V5EL7S/WYO2P7DQVV5RNXGMCUO2HL4RJP4VFUAS/6XPIMU23OJVRY676OG5YVJMWEHWICATX.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})